const RequestCard = (props) => {
    
    let statusIndicator = () => {
        if(props.Status == 'Approved') return 'check'
        else if (props.Status == 'Pending Approval') return 'hourglass_top'
        else return 'close'
    }
    
    return(
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">                            
            <div className={`d-flex requestCard animate__animated animate__fadeInDown`} onClick={props.onClick} data-bs-toggle="modal" data-bs-target="#detailsCard"> 
                
            
                <div className="sideCard">
                    <div 
                        className="d-flex mb-1"
                        style={{alignItems:'center', justifyContent:'start',flexDirection:'row',backgroundColor:'#ffffff47',padding:5,borderBottomColor:'#0063b11a',borderRadius:12}}
                    >
                        <span 
                            className="material-symbols-outlined d-flex"
                            style={{fontSize:20,fontWeight:'400',backgroundColor:'#0063b169',padding:10,borderRadius:100,marginRight:'5%',overflow:'hidden',justifyContent:'center',alignItems:'center',alignContent:'center'}} 
                        >
                            badge
                        </span>

                        <div className="w-100 ellipsis">
                            <p className="mb-0" style={{fontSize:13}}><b>Capex ID</b></p>
                            <small className="mb-0">{props.ID}</small>
                        </div>
                        
                    </div>

                    <div 
                        className="d-flex mb-1"
                        style={{alignItems:'center', justifyContent:'start',flexDirection:'row',backgroundColor:'#ffffff47',padding:5,borderBottomColor:'#0063b11a',borderRadius:12}}
                    >
                        <span 
                            className="material-symbols-outlined d-flex"
                            style={{fontSize:20,fontWeight:'400',backgroundColor:'#0063b169',padding:10,borderRadius:100,marginRight:'5%',overflow:'hidden',justifyContent:'center',alignItems:'center',alignContent:'center'}} 
                        >
                            stack
                        </span>

                        <div className="w-100 ellipsis">
                            <p className="mb-0" style={{fontSize:13}}><b>Request</b></p>
                            <small className="mb-0">{props.Request}</small>
                        </div>
                        
                    </div>

                    <div 
                        className="d-flex mb-1"
                        style={{alignItems:'center', justifyContent:'start',flexDirection:'row',backgroundColor:'#ffffff47',padding:5,borderBottomColor:'#0063b11a',borderRadius:12}}
                    >
                        <span 
                            className="material-symbols-outlined d-flex"
                            style={{fontSize:20,fontWeight:'400',backgroundColor:'#0063b169',padding:10,borderRadius:100,marginRight:'5%',overflow:'hidden',justifyContent:'center',alignItems:'center',alignContent:'center'}} 
                        >
                            paid
                        </span>

                        <div className="w-100 ellipsis">
                            <p className="mb-0" style={{fontSize:13}}><b>Estimated Value</b></p>
                            <small className="mb-0">${props.Budget}</small>
                        </div>
                        
                    </div>
                    
                    <div 
                        className="d-flex mb-1"
                        style={{alignItems:'center', justifyContent:'start',flexDirection:'row',backgroundColor:'#ffffff47',padding:5,borderBottomColor:'#0063b11a',borderRadius:12}}
                    >
                        <span 
                            className="material-symbols-outlined d-flex"
                            style={{fontSize:20,fontWeight:'400',backgroundColor:'#0063b169',padding:10,borderRadius:100,marginRight:'5%',overflow:'hidden',justifyContent:'center',alignItems:'center',alignContent:'center'}} 
                        >
                            person
                        </span>

                        <div className="w-100 ellipsis">
                            <p className="mb-0" style={{fontSize:13}}><b>Requestor</b></p>
                            <small className="mb-0">{props.Requestor}</small>
                        </div>
                        
                    </div>
                    
                    <div 
                        className="d-flex"
                        style={{alignItems:'center', justifyContent:'start',flexDirection:'row',backgroundColor:'#ffffff47',padding:5,borderBottomColor:'#0063b11a',borderRadius:12}}
                    >
                        <span 
                            className="material-symbols-outlined d-flex"
                            style={{fontSize:20,fontWeight:'400',backgroundColor:'#0063b169',padding:10,borderRadius:100,marginRight:'5%',overflow:'hidden',justifyContent:'center',alignItems:'center',alignContent:'center'}} 
                        >
                            approval
                        </span>

                        <div className="w-100 ellipsis">
                            <p className="mb-0" style={{fontSize:13}}><b>Status</b></p>
                            <small className="mb-0">{props.Status !== 'Pending Approval'? props.Status + ' on ' + props.Date : props.Status}</small>
                        </div>
                        
                    </div>
        
                </div>
            </div>
        </div>
    )
}
export default RequestCard