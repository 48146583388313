export let formatNumberWithCommasAndDots = (inputNumber) => {
    // Convert the input number to a string
    let numberString = String(inputNumber);

    // Split the number string into parts before and after the decimal point
    let parts = numberString.split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1] || ''; // If no decimal part, use an empty string

    // Add commas to separate thousands
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Join the integer part and the decimal part with a dot
    let formattedNumber = integerPart + (decimalPart.length > 0 ? '.' + decimalPart : '');

    return formattedNumber;
}
