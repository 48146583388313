import React, {useState} from 'react'
import { Link } from "react-router-dom";
import '../App.css'





let BottomNavbarApprover = (props) => {
    let url = window?.location?.href?.split('/')[3] == '' ? null : window?.location?.href?.split('/')[3][0]?.toUpperCase() + window?.location?.href?.split('/')[3]?.substring(1)
    
    // console.log(window?.location?.href?.split('/')[3])
    const Selected = props.Selected
    let Menu = (props) => {
        
        let isActive = () => {
            if ((props.Text === 'Requests' && Selected === null) || (Selected === props.Text)) {
                // console.log('activate ==> ',props.Text)
                return true
            }
            else return false
        }

        return(
            <div className='col-4 text-center links_navbottom' onClick={props.onClick}>
                <span className={`navbar-icons material-symbols-outlined text-center ${isActive()? 'active' : ''}`}>{props.Icon}</span>
                <p className="mb-0" style={{textDecoration:'none'}}>{props.Text}</p>
            </div>
        )
    }
    
    return (
        
        <div className="mynavbar mobile" Selected = {props.Selected}>               
            <div className="row w-100" style={{marginLeft:'1%',marginTop:'1%'}}>

                
                
                <Menu to='/' Icon='stacks' Text='Requests' Active={true} onClick={props.onClick1}/>

                <Menu to='/requests' Icon='timeline' Text='History' onClick={props.onClick2} />
                
                <Menu to='/settings' Icon='monitoring' Text='Insights' onClick={props.onClick3}/>
      
            </div>

            <img 
                // className='animate__animated rubberBand animate__infinite	infinite'
                src={require('../gfx/logoflame.png')}
                className='navlogo'
            />
        </div>
    
    )
}

export default BottomNavbarApprover