import React, { useState, useEffect, useContext } from "react"


import { ProviderContext } from "../../App";

import app from "../../../package.json";
 /* eslint-disable padded-blocks */
 


let Settings = (props) => {

    const {profile,env} = useContext(ProviderContext)
    const stats = props.Stats

    return (
        <div className="row h-100" >

        
          
            <div className="col-12">
                <div className={`custom-card animate__animated ${props.AnimState !== 0? 'animate__fadeIn':'animate__fadeInLeft'}`}>
                    <div className="row">
                        <div className="col-12">
                            <br/>
                            <div className="w-100 text-center">
                                <img className="mb-3" style={{width:125,height:125,borderRadius:'100%'}} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" />
                                <h6>{profile.name}</h6>
                            </div>

                            <br/>
                            <div className="row" style={{height:'70%'}}>
                                <div className="col-xl-4 col-12 text-center settings-col" >
                                    <div className="d-flex h-100 animate__animated animate__flipInY" style={{position:'relative',justifyContent:'center',alignItems:'center',backgroundColor:'whitesmoke',borderRadius:12}}>
                                        <small style={{position:'absolute',top:'10%'}}>Total Requests Made</small>
                                        <h1 className="reallyBigNumber">{stats.total}</h1>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-12 text-center settings-col" >
                                    <div className="d-flex h-100 animate__animated animate__flipInY" style={{position:'relative',justifyContent:'center',alignItems:'center',backgroundColor:'whitesmoke',borderRadius:12}}>
                                        <small style={{position:'absolute',top:'10%'}}>Approved Requests</small>
                                        <h1 className="reallyBigNumber">{stats.approved}</h1>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-12 text-center settings-col">
                                    <div className="d-flex h-100 animate__animated animate__flipInY" style={{position:'relative',justifyContent:'center',alignItems:'center',backgroundColor:'whitesmoke',borderRadius:12}}>
                                        <small style={{position:'absolute',top:'10%'}}>Denied Requests</small>
                                        <h1 className="reallyBigNumber">{stats.denied}</h1>
                                    </div>
                                </div>
                            </div>

                              

                            <div className="d-flextext-center" style={{margin:'auto',marginTop:'2%'}}>
                                <div class="d-flex settingsCard">
                                    <p class="mb-0 w-100 text-center"><b>Version Number -</b> {app.version}</p>
                                </div>
                            </div>
                           
                            
                        </div>
                    </div>

                    <br/> 

                  
              
                </div>
            </div>
           
        </div>
    )
}

export default Settings