import React, {useState} from 'react'
import { Link } from "react-router-dom";
import '../App.css'





let Navbar = (props) => {
    let url = window?.location?.href?.split('/')[3] == '' ? null : window?.location?.href?.split('/')[3][0]?.toUpperCase() + window?.location?.href?.split('/')[3]?.substring(1)
    
    // console.log(window?.location?.href?.split('/')[3])
    const Selected = props.Selected
    let Menu = (props) => {
        
        let isActive = () => {
            if ((props.Text === 'Dashboard' && Selected === null) || (Selected === props.Text)) {
                // console.log('activate ==> ',props.Text)
                return true
            }
            else return false
        }

        return(
            <div className='col-12 text-center links' onClick={props.onClick}>
                <span className={`navbar-icons material-symbols-outlined text-center ${isActive()? 'active' : ''}`}>{props.Icon}</span>
                <p className="mb-0" style={{textDecoration:'none'}}>{props.Text}</p>
            </div>
        )
    }
    
    return (
        
        <div className="mynavbar" Selected = {props.Selected}>               
            <div className="row" >

                <div style={{marginBottom:'10%',marginTop:'15%'}} />
                
                <Menu to='/' Icon='dashboard' Text='Dashboard' Active={true} onClick={props.onClick1}/>

                <Menu to='/requests' Icon='stacks' Text='Requests' onClick={props.onClick2} />
                
                <Menu to='/settings' Icon='settings' Text='Settings' onClick={props.onClick3}/>
      
            </div>

            <img 
                // className='animate__animated rubberBand animate__infinite	infinite'
                src={require('../gfx/logoflame.png')}
                className='navlogo'
            />
        </div>
    
    )
}

export default Navbar