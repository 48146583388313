
let Ring = (props) => {
    let icon = () => {
        if(props.Status == 'pending') return 'hourglass'
        else if(props.Status == 'approved') return 'check'
        else return 'close'
    }
    return(
        <div className="w-100 text-center" style={{justifyContent:'center'}}>
            <div className={`approvalCircle ${props.Status}`} >
                <span 
                    className="material-symbols-outlined" 
                    style={{
                        fontSize:72,
                        bordeRadius: '100%',
                        padding: 5
                    }}>{icon()}</span>
            </div>

            <p style={{color:'black',width:'65%',margin:'auto'}}>{props.Text}</p>
        </div>   
    )
   
}

export default Ring