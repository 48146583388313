import React, { useState, useEffect, useMemo } from "react"

import { Player, Controls } from '@lottiefiles/react-lottie-player';




/* eslint-disable padded-blocks */
 
console.log('load waitlist page')
let Waitlist = (props) => {

    
    return (
        <div className='row w-100 h-100'>
            <div className='col-2' />

            <div className='col-8'> 
                <div className='custom-card animate__animated animate__fadeIn' style={{margin:'auto',height:'70%',marginTop:'10%',overflow:'hidden'}}>

                    <img width={75} style={{position:'absolute',color:'#1976D2',left:'47.5%',marginTop:'0%',borderRadius:100}} src={require('../../src/gfx/capex-logos.jpeg')} />
                    
                    <div className='' style={{width:350,height:'100%',justifyContent:'center',alignItems:'center',margin:'auto',marginTop:'8%'}}>
                        <Player
                            autoplay
                            loop
                            src='https://lottie.host/4f8cbb30-620a-4e6a-86a9-e87c0a3cd817/rUPnIMdXC6.json'
                            style={{ width: '100%',justifyContent:'center', alignItems:'center'}}
                        />
                        <h6 className="text-center">An admin has been notified of your attempt to access Capex. Permission will be granted if accepted. </h6>
                    </div>
                    

                    <img width={250} style={{position:'absolute',color:'#1976D2',left:'43%',bottom:'10%'}} src={require('../../src/gfx/capex-logos_transparent.png')} />
                </div>
            </div>
        </div>
    )
}

export default Waitlist