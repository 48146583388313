import React, { useState, useContext, useMemo } from "react"


import { ProviderContext } from "../../App";
import FilterCard from "../../components/Cards/FilterCard";
import DatePicker from "react-datepicker";
import app from "../../../package.json";

const Settings = (props) => {

    const users = props.Users
    const fiscalBudget = props.FiscalBudget
    const spent = props.Spent
    const {profile,env} = useContext(ProviderContext)
    const [roleValue, isRoleValue] = useState('none')
    const [roleValueOG, isRoleValueOG] = useState('none')
    const [roleUser, isRoleUser] = useState('none')
    const [newselection,isNewSelection] = useState(false)
    const [departments, isDepartments] = useState([])
    const [settingsmenu, setSettingsMenu] = useState('Set Budget')
    

    const [partnumber, setPartNumber] = useState('')
    const [partdesc, setPartDesc] = useState('')
    const [searchword, setSearchWord] = useState('')
    const [approveList, setApproveList] = useState({});

    const [amount, setAmount] = useState('')
    const [fiscal, setFiscal] = useState('')
    const [fromDate, setFromDate] = useState(new Date)
    const [toDate, setToDate] = useState(new Date)
    
    console.log('amount spent so far ==> ',spent)
    const changeRole = async () => {
        let arr = []
        console.log('updating role of ' + roleUser + ' as ' + roleValue)
        
        /* eslint-disable */
        $('.pills.active').each(function(index,elem){
            console.log($(elem).text().substring(0,2))
            arr.push($(elem).text().substring(0,2))
        })  

        const departments = arr.join(',')
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/changeRole?ID=${roleUser}&Permission=${roleValue}&Departments=${departments}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('role updated ==> ', JSON.parse(result))
            let res = JSON.parse(result)
          
            isRoleValueOG(roleValue)
            isNewSelection(false)
            console.log(res)
            alert(res)
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    const createPart = async () => {

        let data = {
            partNum: partnumber,
            searchWord: searchword,
            partDesc: partdesc
        }

        let stringifiedData = JSON.stringify(data)

        console.log('to create ===> ', data, stringifiedData)
         
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: stringifiedData
        };

        await fetch(`${env}/api/capex/createPart`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('part created ==> ', JSON.parse(result))
            alert(partdesc + ' has been added to the part list!')
            setPartDesc('')
            setPartNumber('')
            setSearchWord('')
          
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    const setBudget = async () => {

        let data = {
            amount: amount,
            spent: spent,
            from: fromDate,
            to: toDate,
            fiscal: fiscal,
            setBy: profile.name,
            setOn: new Date
        }

        let stringifiedData = JSON.stringify(data)

        console.log('to create ===> ', data, stringifiedData)
         
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: stringifiedData
        };

        await fetch(`${env}/api/capex/setBudget`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('budget set ==> ', JSON.parse(result))
            alert('The budget has been for fiscal year ',fiscal,'. It can not be changed until the end of the duration.')
            setAmount('')
            setFiscal('')
            setToDate(new Date)
            setFromDate(new Date)
          
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    const getDepartments = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/getdepartments`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('all departments ==> ', JSON.parse(result))
            isDepartments(JSON.parse(result))
        })
        .catch(error => {
            console.log('error', error)
            // isDepartments(['An error occured.'])
        });
    } 
  
    let userSelect = users?.map((data) => (
        <option value={data.ID} permission={data.Permission} departments={data.Department}>{data.Name} {data.Banned == '1'? ' [Banned]' : ''}</option>
    ))

  
    
    const handlePillClick = (lastTwoDigits) => {
        setApproveList((prevList) => ({
          ...prevList,
          [lastTwoDigits]: !prevList[lastTwoDigits]
        }));
        console.log('approved list ==> ',approveList)
      //   isApproveList(approveList)
    };

    const Pills = ({ isActive, label, onClick }) => {
        return (
          <p
            className={`pills ${isActive ? 'active' : ''}`}
            onClick={onClick}
          >
            {label}
          </p>
        );
    };
    

    let departmentsCard = departments.map((data) => {

       
        return(
        
            <Pills
                key={data?.LastTwoDigits} // Ensure each component has a unique key
                isActive={approveList[data?.LastTwoDigits]}
                label={data?.LastTwoDigits + ' / ' + data?.DepartmentDescription}
                onClick={() => {
                    handlePillClick(data?.LastTwoDigits)
                    isNewSelection(true)
                }}
            />
        )
    })

    useMemo(() => {
        getDepartments()

        if(fiscalBudget){
            setAmount(fiscalBudget.amount)
            setFiscal(fiscalBudget.fiscal)
            setToDate(new Date(fiscalBudget.to))
            setFromDate(new Date(fiscalBudget.from))
        }
    },[])

  
    return (
        <div className="row h-100" >

            <div className="modal" id="editCard" tabIndex="-1" data-bs-keyboard="false">
                <div className="modal-dialog modal-fullscreen animate__animated animate__zoomInUp animate__faster">
                    
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className='details-card'>
                                <br/>
                                <div className="w-100 h-100" style={{overflowY:'auto'}}>
                                    <div className="blue-card">
                                        <div className="d-flex">
                                            <h1 style={{width:'100%'}}>Parts</h1>
                                           
                                        </div>
                                        
                                     
                                    </div>

                                    <br/><br/>

                                    <div className="col-12" style={{margin:'auto'}}>

                                        <div className="blue-card">
                                            <div className="card-inside" style={{backgroundColor:'white'}}> 
                                                <div className="row" >
                                                    <div className="col-4 text-center"><b>Part Number</b></div>
                                                    <div className="col-4 text-center"><b>Search Word</b></div>
                                                    <div className="col-4 text-center"><b>Description</b></div>
                                                </div>
                                            </div>

                                            {/* {renderUsers} */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
          
            <div className="col-12 h-100">
                <div className={`custom-card animate__animated animate__fadeIn`} style={{overflowY:'auto'}}>
                    <div className="row">
                        <div className="col-12">
                           
                            <div 
                                className="w-50 text-center" 
                                style={{
                                    backgroundColor: 'whitesmoke',
                                    borderRadius: 12,
                                    padding: 15,
                                    margin: 'auto'
                                }}
                            >
                                <img className="mb-3" style={{width:125,height:125,borderRadius:'100%'}} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" />
                                <h6>{profile.name}</h6>
                            </div>
                            
                        </div>
                    </div>

                  

                    <div className='d-flex' style={{width:'100%',justifyContent:'center',padding:10,marginLeft:'3%', borderBottom:'2px solid whitesmoke',marginBottom:'3%'}}>
                        <FilterCard
                            Name='Set Budget'
                            Active={settingsmenu} 
                            onClick={() => {
                                setSettingsMenu('Set Budget')
                            }}
                            
                        />

                        <FilterCard
                            Name='Change Roles'
                            Active={settingsmenu} 
                            onClick={() => {
                                setSettingsMenu('Change Roles')
                            }}
                            
                        />

                        <FilterCard
                            Name='Create Product'
                            Active={settingsmenu} 
                            onClick={() => {
                                setSettingsMenu('Create Product')
                            }}
                            
                        />
                    </div>

                    {settingsmenu == 'Set Budget' &&
                        <div className="w-50 mb-5" style={{margin:'auto'}}>
                            <div className="w-100" style={{backgroundColor:'whitesmoke',borderRadius:12,padding:15,position:'relative'}}>
                                <h6 className="mb-3">Set Budget</h6>
                                
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Amount</span>
                                    <input 
                                        placeholder='1000000...'
                                        className="form-control"
                                        value={amount}
                                        onChange={(e) => {
                                            let val = e.target.value
                                            setAmount(val)
                                        }}
                                        disabled={new Date() <= new Date(fiscalBudget?.to) ? true : false}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Fiscal Year</span>
                                    
                                    <input 
                                        value={fiscal}
                                        placeholder='1...'                                 
                                        className="form-control"
                                        onChange={(e) => {
                                            let val = e.target.value
                                            setFiscal(val)
                                        }}
                                        disabled={new Date() <= new Date(fiscalBudget?.to) ? true : false}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">From</span>
                                    
                                    <DatePicker 
                                        selected={fromDate} 
                                        onChange={(date) => {
                                            setFromDate(date)
                                            
                                        }}
                                        disabled={new Date() <= new Date(fiscalBudget?.to) ? true : false}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">To</span>
                                    
                                    <DatePicker 
                                        selected={toDate} 
                                        onChange={(date) => {
                                            setToDate(date)
                                            
                                        }}
                                        disabled={new Date() <= new Date(fiscalBudget?.to) ? true : false}
                                    />
                                </div>
                                

                            

                                <div className="w-100 text-center">
                                    <button 
                                        type="button" 
                                        class={`btn btn-primary w-25 ${amount.length > 0 && fiscal.length > 0? '' : 'disabled' } `}
                                        onClick={() => setBudget()}
                                        style={{borderRadius:100}}
                                        disabled={new Date() <= new Date(fiscalBudget?.to) ? true : false}
                                    >
                                        Set
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    }
                   
                    {settingsmenu == 'Change Roles' &&
                        <div className="w-50 mb-5" style={{margin:'auto'}}>
                            <div className="w-100" style={{backgroundColor:'whitesmoke',borderRadius:12,padding:15}}>
                                <h6 className="mb-3">Change Roles</h6>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">User</span>
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            value={roleUser}
                                            defaultValue='none' 
                                            onChange={(e) => {
                                                const selectedIndex = e.target.selectedIndex;
                                                const selectedOption = e.target[selectedIndex];
                                                const customDataValue = selectedOption.getAttribute('permission');
                                                const customDepartments = selectedOption.getAttribute('departments')?.split(',');
                                                const departments = {}

                                                console.log('selected departments ==> ',customDepartments)
                                                if(customDepartments && customDepartments.length > 0){
                                                    for(let i = 0; i < customDepartments.length; i++){
                                                        console.log('select departments ==>',customDepartments[i])
                                                        departments[customDepartments[i]] = true
                                                    }
                                                }
                                                console.log('departments pre set ==> ',departments)

                                                isRoleUser(e.target.value)
                                                isRoleValue(customDataValue)
                                                isRoleValueOG(customDataValue)
                                                setApproveList({})
                                                setApproveList(departments)
                                                console.log(e.target.value)
                                            }
                                        }>
                                            <option selected value="none" permission="none">None</option>
                                            {userSelect}
                                        </select>
                                        <label for="departmentSelect">Select a user</label>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Role</span>
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            value={roleValue}
                                            onChange={(e) => {
                                                
                                                isRoleValue(e.target.value)
                                                console.log(roleValue + ' vs ' + roleValueOG)
                                                setApproveList({})
                                            }}
                                        >
                                            <option selected value="none">None</option>
                                            <option value="basic" >Basic</option>
                                            <option value="approver_level1">Approver Level 1</option>
                                            <option value="approver_level2">Approver Level 2</option>
                                            <option value="approver_level3">Approver Level 3</option>
                                            <option value="approver_level4">Approver Level 4</option>
                                            <option value="admin">Admin</option>
                                            <option value="ban">Ban</option>
                                        </select>
                                        <label for="departmentSelect">Select a role</label>
                                    </div>
                                </div>

                                {(roleValue.split('_')[0] == 'approver') &&
                                    <>
                                        <h6 style={{marginLeft:10}}>{roleValue.split('_')[1] == 'level1'? '(Recommended)' : '(Optional)'}</h6>
                                        <div className=" d-flex flex-wrap pill-container">
                                            {departmentsCard}
                                        </div>
                                    </>
                                }
                                

                                <div className="w-100 text-center">
                                    <button 
                                        type="button" 
                                        class={`btn btn-primary w-25 ${newselection || (roleUser !== 'none' && roleValue !== roleValueOG)? '' : 'disabled' } `}
                                        onClick={() => changeRole()}
                                        style={{borderRadius:100}}
                                    >
                                        Update
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    }
                    
                    {settingsmenu == 'Create Product' &&
                        <div className="w-50 mb-5" style={{margin:'auto'}}>
                            <div className="w-100" style={{backgroundColor:'whitesmoke',borderRadius:12,padding:15,position:'relative'}}>
                                <h6 className="mb-3">Create Product</h6>
                                {/* <span 
                                    class="material-symbols-outlined text-center active editButton"
                                    data-bs-toggle="modal" data-bs-target="#editCard"
                                >edit</span> */}


                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Part Number</span>
                                    <input 
                                        placeholder='Part number...'
                                        className="form-control"
                                        value={partnumber}
                                        onChange={(e) => {
                                            let val = e.target.value
                                            setPartNumber(val)
                                        }}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Part Description</span>
                                    
                                    <input 
                                        value={partdesc}
                                        placeholder='Part description...'                                 
                                        className="form-control"
                                        onChange={(e) => {
                                            let val = e.target.value
                                            setPartDesc(val)
                                        }}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Search Word</span>
                                    
                                    <input 
                                        value={searchword}
                                        placeholder='search word...'                                 
                                        className="form-control"
                                        onChange={(e) => {
                                            let val = e.target.value
                                            setSearchWord(val)
                                        }}
                                    />
                                </div>

                            

                                <div className="w-100 text-center">
                                    <button 
                                        type="button" 
                                        class={`btn btn-primary w-25 ${partdesc.length > 0 && searchword.length > 0 && partnumber.length > 0? '' : 'disabled' } `}
                                        onClick={() => createPart()}
                                        style={{borderRadius:100}}
                                    >
                                        Create
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    }
                   
                   
                    <div className="d-flex text-center w-50" style={{margin:'auto',marginTop:'2%'}}>
                        <div class="d-flex settingsCard">
                            <p class="mb-0 w-100 text-center"><b>Version Number -</b> {app.version}</p>
                        </div>
                    </div>
              
                </div>
            </div>
           
        </div>
    )
}

export default Settings