import React, { useState, useEffect, useRef, useContext } from "react"
import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import RequestCard from "../components/Cards/RequestCard"
import { ProviderContext } from "../App";

import app from '../../package.json'

import BottomNavbarApprover from "../components/BottomNavbarApprover";
import NavbarApprover from "../components/NavbarApprover";
import { Player, Controls } from '@lottiefiles/react-lottie-player';





/* eslint-disable padded-blocks */
function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

let formatText = (text) => { 
    if(text.toLowerCase() == 'pending') return ' Approval'
    else return ''
}

let Approver = (props) => {
    const modalRef = useRef(null);
    const [data, isData] = useState([])
    const [allrequests, isAllRequests] = useState([])
    const [loaded, isLoaded] = useState(false)
    const [dataFromMail, isDataFromMail] = useState({})
    const [loading, setLoading] = useState(true)
    const departments = props.Departments

    const [search, isSearching] = useState(false)
    const [searchRes, isSearchRes] = useState([])
    const [error, isError] = useState({
        error: false,
        message: ''
    })

    const [currRequest, isCurrRequest] = useState(null)
    const [requestsolved, isRequestSolved] = useState(false)
    const [resolution, isResolution] = useState(null)
    const {access,profile,env} = useContext(ProviderContext)
    const level = access.length > 0 ? parseInt(access[access.length - 1]) : null
    const [menu, isMenu] = useState('Requests')
    const [submenu, isSubmenu] = useState('All')
    const [currspent, isCurrSpent] = useState(0)
    const [nextapprover, isNextApprover] = useState(null)

    const [selected, isSelected] = useState('Requests')
    const [downloadQuote, setDownloadQuote] = useState(null)
    const [downloadFormat, setDownloaFormat] = useState(null)

    const [showlegend, setLegend] = useState(false)
    
    const [budgetPerDepartment, isBudgetPerDepartment] = useState({
        label: [],
        data: []
    })
    const [spentMonthly, isSpentMonthly] = useState({
        Sep:0,oct:0,nov:0,dec:0,jan:0,feb:0,mar:0,apr:0,may:0,jun:0,jul:0,aug:0
    })
   
    
    const [stats, isStats] = useState({
        total:0,
        approved:0,
        denied:0,
        approved_money:0
    })

    
    // console.log('this user level ==> ', level)
    


    let formatMoney = (p) => {
        if(p > 999 && p < 9999){
            let str = '' + p + ''
            return str.substring(0,1) + 'k'
        }
        else if(p > 9999 && p < 99999){
            let str = '' + p + ''
            return str.substring(0,2) + 'k'
        }
        else if(p > 99999 && p < 999999){
            let str = '' + p + ''
            return str.substring(0,3) + 'k'
        }
        else if(p > 999999 && p < 9999999){
            let str = '' + p + ''
            return str.substring(0,1) + '.' + str.substring(1,2) + 'm'
        }
        else{
            let str = '' + p + ''
            return str.substring(0,2) + '.' + str.substring(2,3) + 'm'
        }
    }

    let formatNumberWithCommasAndDots = (inputNumber) => {
        // Convert the input number to a string
        let numberString = String(inputNumber);
    
        // Split the number string into parts before and after the decimal point
        let parts = numberString.split('.');
        let integerPart = parts[0];
        let decimalPart = parts[1] || ''; // If no decimal part, use an empty string
    
        // Add commas to separate thousands
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
        // Join the integer part and the decimal part with a dot
        let formattedNumber = integerPart + (decimalPart.length > 0 ? '.' + decimalPart : '');
    
        return formattedNumber;
    }

    let getRequests = async () => {
        
        // console.log('check for new requests...',departments)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRequestsApprover?level=${level}&departments=${departments}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // console.log('all requests ==> ', JSON.parse(result))
            const res = JSON.parse(result)
          
           

            isData(res)
            setLoading(false)
            setTimeout(getRequests, 2000)
        })
        .catch(error => {
            console.log('error', error)
            isLoaded(false)
        });

    }

    let getRequestFromMail = async (id) => {
        
        console.log('check for new requests...')
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRequestFromMail?id=${id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // console.log('all requests ==> ', JSON.parse(result))
            let res = JSON.parse(result)
          
            console.log(data)
            isDataFromMail(res[0])
            isCurrRequest(res[0])

           
            setTimeout(() => {
                 // eslint-disable-next-line
                $('#detailsCard').modal('show')
            }, 450);
        //    alert('hello?')

            setTimeout(getRequests, 5000)
        })
        .catch(error => {
            console.log('error', error)
        });

    }



    let getStats = async () => {
        
        console.log('check for new requests... ===> ', profile.username)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getStats?level=${level}&id=${profile.username}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('stats ==> ', JSON.parse(result))
            
            const data = JSON.parse(result)
            let total = data.length - 1
            let approved = 0
            let denied = 0
            let approved_money = 0

            let months = {};
            let label = [];
            let dataLabel = [];

            console.log('data from stats ==> ', data)

            
            for(let value of data){
                if(value.Status == 'approved'){
                    approved_money += parseInt(value.EstimatedValue)
                } 
                
                if(value[`Approval${level}`]  == 'approved' && value[`Approval${level}ByEmail`] == profile.username){
                    approved += 1

                    months[value.ApprovalDateMonth] = (months[value.ApprovalDateMonth] || 0) + parseInt(value.EstimatedValue);
    
                    if (!label.includes(value.Department)) {
                        label.push(value.Department);
                        dataLabel.push(parseInt(value.EstimatedValue));
                    } else {
                        const index = label.indexOf(value.Department);
                        dataLabel[index] += parseInt(value.EstimatedValue);
                    }
                }
                else if(data[`Approval${level}`]== 'denied'  && data[`Approval${level}ByEmail`] == profile.username) denied += 1
            }


            isStats({
                total: total,
                approved: approved,
                denied: denied,
                approved_money: approved_money,
            })

           
            isBudgetPerDepartment({
                label: label,
                data: dataLabel
            });

            isSpentMonthly({
                oct: months['oct'] || 0,
                nov: months['nov'] || 0,
                dec: months['dec'] || 0,
                jan: months['jan'] || 0,
                feb: months['feb'] || 0,
                mar: months['mar'] || 0,
                apr: months['apr'] || 0,
                may: months['may'] || 0,
                jun: months['jun'] || 0,
                jul: months['jul'] || 0,
                aug: months['aug'] || 0,
                sep: months['sep'] || 0
            });

            // console.log('monthly data ===> ',months)
            isAllRequests(data)

            console.log('current val of allrequests ==> ',data)

            isLoaded(true)
           
            

        })
        .catch(error => {
            console.log('error', error)
            isError({
                error:true,
                message: error.message
            })
            isLoaded(true)
        });

    }

    const getNextApprover = async (level) => {
       
       
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getNextApprover?level=${level}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log('first approver ==> ', JSON.parse(result))
            const res = JSON.parse(result)

            if(res.length > 0){
                isNextApprover({
                    ID: res[0].ID,
                    Name: res[0].Name
                })
            }
            else{
                isNextApprover({
                    ID: 'khadim.mbow@heathus.com',
                    Name: 'Khadim Mbow'
                })
            }
        })
        .catch(error => {
            console.log('error', error)
        });
    } 

    let solveRequest = async (status,comment) => {
        
        

        let spent = currspent + parseInt(currRequest?.EstimatedValue)
        
        console.log('total spent ===> ',spent)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/solveRequest?level=${currRequest?.Approval1 == 'pending'? 1 : level}&ID=${currRequest?.ID}&name=${profile.name}&status=${status}&steps=${currRequest?.ApprovalRequired}&email=${profile.username}&spent=${spent}&budgetcode=${currRequest?.BudgetCode}&comment=${comment}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // console.log('all requests ==> ', JSON.parse(result))

            const requiredApproval = currRequest?.ApprovalRequired
            const left = requiredApproval - level
            
            isRequestSolved(true)
            isResolution(status)

            // subject, body, address, displayName
            if(level == 1){
                sendEmail(
                    `Approval Status for your request with ID - ${currRequest.ID}`, 
                    `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}${comment == null? '':' because ' + comment}. There is ${left == 0 || status == 'denied'? 'no' : left} approvals left in the process. For any further inquiries or questions, please contact them.`,
                    currRequest.OriginatorEmail,
                    currRequest.OriginatorName
                )
            } 
            else if(level == 2){
                sendEmail(
                    `Approval Status for your request with ID - ${currRequest.ID}`, 
                    `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}${comment == null? '':' because ' + comment}. There is ${left == 0 || status == 'denied'? 'no' : left} approvals left in the process. For any further inquiries or questions, please contact them.`,
                    currRequest.OriginatorEmail,
                    currRequest.OriginatorName
                )
                if(currRequest.ApprovalRequired > 2){
                    sendEmail(
                        `Request with ID - ${currRequest.CapexID} by ${currRequest.OriginatorName.split(' ')[0]} Needs 2nd Approval`, 
                        `Hi, ${currRequest.OriginatorName}, has requested item ${currRequest.ItemDescription} with an estimated budget of ${currRequest.EstimatedValue}$. It has already been approved by the department head. You can review this Capex by clicking the following link: http://lemon-glacier-0a9730b10.4.azurestaticapps.net/?id=${currRequest.CapexID}`,
                        nextapprover.ID,
                        profile.name
                    )
                }
            } 
            else if(level == 3){
                sendEmail(
                    `Approval Status for your request with ID - ${currRequest.ID}`, 
                    `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}${comment == null? '':' because ' + comment}. There is ${left == 0 || status == 'denied'? 'no' : left} approvals left in the process. For any further inquiries or questions, please contact them.`,
                    currRequest.OriginatorEmail,
                    currRequest.OriginatorName
                )
                if(currRequest.ApprovalRequired > 3){
                    sendEmail(
                        `Request with ID - ${currRequest.CapexID} by ${currRequest.OriginatorName.split(' ')[0]} Needs 2nd Approval`, 
                        `Hi, ${currRequest.OriginatorName}, has requested item ${currRequest.ItemDescription} with an estimated budget of ${currRequest.EstimatedValue}$. It has already been approved by the CEO. You can review this Capex by clicking the following link: http://lemon-glacier-0a9730b10.4.azurestaticapps.net/?id=${currRequest.CapexID}`,
                        nextapprover.ID,
                        profile.name
                    )
                }
            } 
            else{
                sendEmail(
                    `Approval Status for your request with ID - ${currRequest.ID}`, 
                    `Hi ${currRequest.OriginatorName.split(' ')[0]}, your request for ${currRequest.ItemDescription} has been ${status} by ${profile.name}${comment == null? '':' because ' + comment}. For any further inquiries or questions, please contact them.`,
                    currRequest.OriginatorEmail,
                    currRequest.OriginatorName
                )
            } 
            

            setTimeout(() => {
                isRequestSolved(false)
                isResolution(null)
                // eslint-disable-next-line
                $('#detailsCard').modal('hide')
            }, 8000);
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    let getQuote = async (ID) => {
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        try{
            await fetch(`${env}/api/capex/getQuote?ID=${ID}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                
                
                const data = JSON.parse(result)
                const quote = data[0].QuoteValue
                if(quote.length > 10){
                    console.log('convert to blob')
                    dataURItoFile(quote)
                }
        
            })
            .catch(error => {
                console.log('error', error)
            })
        }
    
        catch(err){
            console.error('error ==> ', err)
        }
       
    
    }

    let sendEmail = async (subject, body, address, displayName) => {
        
        console.log('email ===> ', subject, body, address, displayName)
        
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/sendEmail?subject=${subject}&body=${body}&address=${address}&displayName=${displayName}`, requestOptions)
        .then(response => response.text())
        .then(result => {
           console.log('email sent !')
        })
        .catch(error => {
            console.log('error', error)
        });

    }

    function dataURItoFile(dataURI) {
        // Extract the Base64 content
        if(dataURI && dataURI.length > 15){
            const base64Content = dataURI.split(',')[1];
            const fileType = dataURI.split('/')[1].split(';')[0]
          
            // decode Base64 string into binary data
            const binaryString = atob(base64Content);
          
            // Create a typed array from binary string
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
          
            // Create Blob from the typed array with appropriate MIME type
            const blob = new Blob([byteArray], { type: dataURI.split(';')[0] });
          
            // Optionally, create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);
             
            let getFileTypeFromDataURI = (type) => {
            
                switch (type) {
                    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                        return 'docx';
                    case 'pdf':
                        return 'pdf';
                    case 'png':
                        return 'png';
                    case 'jpeg':
                        return 'jpg';
                    case 'jpg':
                        return 'jpg';
                    default:
                        throw new Error('Unsupported file type');
                }
            }
          
            console.log('converted blob file ===> ',fileURL)
          
            setDownloadQuote(fileURL)
            setDownloaFormat(getFileTypeFromDataURI(fileType))
            // return blob; // or return the Blob object if needed
        }
     
    }

    let renderCards = data?.map((data) => {

        let getDate = () => {
            if (data.Status !== 'pending' && data.Approval4Date) return data.Approval4Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval3Date) return data.Approval3Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval2Date) return data.Approval2Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval1Date) return data.Approval1Date.toString().substring(0,10)
            else return 'N/A'
        }
       
        return (
            <RequestCard 
                ID = {data.ID}
                Request={data.ItemDescription? data.ItemDescription : 'N / A'}  
                Requestor={data.OriginatorName}
                Budget={data.EstimatedValue}
                Status = {data.Status? capitalizeFirstLetter(data.Status) + formatText(data.Status) : 'Pending Approval'}
                Date = {getDate()}
                onClick={() => {
                    let int = parseInt(data.EstimatedValue)
                    isCurrRequest(data)
                    console.log(typeof(int))

                    setDownloadQuote(null)
                    setDownloaFormat(null)

                    getQuote(data.ID)
                }}
            />
        )
       
    })
    
    let searchCards = searchRes?.map((data) => {

        let getDate = () => {
            if (data.Status !== 'pending' && data.Approval4Date) return data.Approval4Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval3Date) return data.Approval3Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval2Date) return data.Approval2Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval1Date) return data.Approval1Date.toString().substring(0,10)
            else return 'N/A'
        }
       
        return (
            <RequestCard 
                ID = {data.ID}
                Request={data.ItemDescription? data.ItemDescription : 'N / A'}  
                Requestor={data.OriginatorName}
                Budget={data.EstimatedValue}
                Status = {data.Status? capitalizeFirstLetter(data.Status) + formatText(data.Status) : 'Pending Approval'}
                Date = {getDate()}
                onClick={() => {
                    let int = parseInt(data.EstimatedValue)
                    isCurrRequest(data)
                    console.log(typeof(int))

                    setDownloadQuote(null)
                    setDownloaFormat(null)

                    getQuote(data.ID)
                }}
            />
        )
       
    })

    let renderAll = allrequests.map((data) => {

        let getDate = () => {
            if(data.Status == 'canceled') return data?.CompletedDate? data.CompletedDate?.toString().substring(0,10) : 'N/A'
            else if (data.Status !== 'pending' && data.Approval4Date) return data.Approval4Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval3Date) return data.Approval3Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval2Date) return data.Approval2Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval1Date) return data.Approval1Date.toString().substring(0,10)
            else return 'N/A'
        }
       
        return (
            <RequestCard 
                ID = {data.ID}
                Request={data.ItemDescription? data.ItemDescription : 'N / A'}  
                Requestor={data.OriginatorName}
                Budget={data.EstimatedValue}
                Status = {data.Status? capitalizeFirstLetter(data.Status) + formatText(data.Status) : 'Pending Approval'}
                Date = {getDate()}
                onClick={() => {
                    let int = parseInt(data.EstimatedValue)
                    isCurrRequest(data)
                    console.log(typeof(int))

                    setDownloadQuote(null)
                    setDownloaFormat(null)

                    getQuote(data.ID)
                }}
            />
        )
       
    })

  

    const Menu = (props) => (
        <div className={`selector ${props.Title == menu? 'active' : ''}`} onClick={props.onClick}>{props.Title}</div>
    )

    const Submenu = (props) => (
        <div className={`selector ${props.Title == 'Denied'? 'denied' : ''} ${props.Title == submenu? 'active' : ''}`} onClick={props.onClick}>{props.Title}</div>
    )

    useEffect(() => {
        console.log('the access level is ===>', access[access.length - 1])
        console.log('user profile ===> ',profile)
        getRequests()
        getStats()
        getNextApprover(level + 1)

        const url = new URL(window.location.href);
        const paramValue = url.searchParams.get('id');

        if(paramValue && paramValue.length > 1){
            console.log('param values ===> ',paramValue)
            getRequestFromMail(paramValue)
        }
    },[])

  
    return (
        <div className="row h-100" >

            <div className="modal" id="detailsCard" tabIndex="-1" data-bs-keyboard="false" >
                <div className="modal-dialog modal-fullscreen animate__animated animate__zoomInUp animate__faster">
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className='details-card' ref={modalRef}>

                                <span 
                                    className="material-symbols-outlined btn-close" 
                                    data-bs-dismiss="modal" aria-label="Close"
                                    style={{
                                        position:'absolute',
                                        right:'3%'
                                    }}
                                >close</span>

                                <br/>
                                {!requestsolved?
                                    (
                                        <div className="w-100 h-100" style={{overflowY:'auto',overflowX:'hidden'}}>
                                            <div className="blue-card">
                                                <div className="d-flex">
                                                    <h5 className="mb-0" style={{width:'100%'}}>[{currRequest?.ItemDescription}]  | Request by - {currRequest?.OriginatorName}</h5>
                                                </div>
                                                
                                            </div>
        
                                            <br/>
        
                                            
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row blue-card g-2 needs-validation testform" novalidate>
                                                        <h5>Purchase</h5>
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Asset Category</span>
                                                                <input className="form-control" value={currRequest?.AssetCategory} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3 flex-nowrap">
                                                                <span className="input-group-text" id="basic-addon1">Quantity</span>
                                                                <input className="form-control" value={currRequest?.Quantity} disabled  />
                                                            </div>
                                                            
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text " id="basic-addon1">Justification of Purchase</span>
                                                                <textarea className="form-control" value={currRequest?.JustificationOfPurchase} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Ship To Address</span>
                                                                <textarea className="form-control" value={currRequest?.ShipToAddress} disabled  />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                                <textarea className="form-control" value={currRequest?.AddressTo} disabled  />
                                                            </div>
                                                        </div>
                                                        
        
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">CAPEX or PURCHASE REQ</span>
                                                                    <input className="form-control" value={currRequest?.ID} disabled  />
                                                                </div>
                                                                
                                                                <div className="input-group mb-3" style={{position:'relative'}}>
                                                                    <span className="input-group-text" id="basic-addon1">Part Number</span>
                                                                    <input className="form-control" value={currRequest?.PartNumber} disabled  />
                                                                </div>
                                                                
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">Item Description</span>
                                                                    <textarea className="form-control" value={currRequest?.ItemDescription} disabled  />
                                                                </div>
        
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">Location</span>
                                                                    <textarea className="form-control" value={currRequest?.AddressFrom} disabled  />
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                                    <textarea className="form-control" value={currRequest?.Location} disabled  />
                                                                </div>
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
        
                                            <br/>
        
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="blue-card g-2 row">
                                                        <h5>Cost</h5>
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Vendor</span>
                                                                <input className="form-control" value={currRequest?.Vendor} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Quote #</span>
                                                                <input className="form-control" value={currRequest?.Quote} disabled  />
                                                            </div>

                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Quote Attachment</span>

                                                                {downloadQuote?
                                                                    (
                                                                        <>
                                                                            <input className="form-control" disabled />
                                                                            <a className='quoteDownload' href={downloadQuote} download={currRequest?.ID + `_quote.${downloadFormat}`}>Download</ a>
                                                                        </>
                                                                        
                                                                            
                                                                    
                                                                    )

                                                                    :

                                                                    (
                                                                        <input className="form-control" value='There is no attached quote' disabled  />
                                                                    )
                                                                }
                                                                
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Department</span>
                                                                <input className="form-control" value={currRequest?.Department} disabled  />
                                                            </div>
        
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Budgeted</span>
                                                                <input className="form-control" value={currRequest?.Budgeted == 'true'? 'Yes' : 'No'} disabled  />
                                                            </div>
        
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Unbudgeted ROI</span>
                                                                <input className="form-control" value={currRequest?.Unbudgeted? currRequest?.Unbudgeted : 'N/A'} disabled  />
                                                            </div>
                                                        </div>
                                                        
        
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Quote Date</span>
                                                                <input className="form-control" value={currRequest?.QuoteDate} disabled  />
                                                                
                                                            </div>
                                                                
                                                            <div className="input-group mb-3" style={{position:'relative'}}>
                                                                <span className="input-group-text" id="basic-addon1">Estimated Value</span>
                                                                <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.EstimatedValue)} disabled  />
                                                                <span className="input-group-text">$</span>
                                                            
                                                            </div>
                                                            
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Project Code</span>
                                                                <input className="form-control" value={currRequest?.ProjectCode} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Budget Code</span>
                                                                <input className="form-control" value={(currRequest?.BudgetCode == 'none' || currRequest?.BudgetCode == '')? 'N/A' : currRequest?.BudgetCode} disabled  />
                                                            </div>
        
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="basic-addon1">Budget Amount</span>
                                                                <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.BudgetAmount)} disabled  />
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <br/> 
        
                                            <div class="row" style={{marginBottom:'15%'}}>
        
                                                <div class="col-12">
                                                    <div className="blue-card row g-2">
                                                        <h5>Contact</h5>
                                                        <div className="col-xl-6 col-sm-12">
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Originator</span>
                                                                <input className="form-control" value={currRequest?.OriginatorName} disabled  />
                                                            </div>
        
        
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Contact Phone Number</span>
                                                                <input className="form-control" value={currRequest?.Phone} disabled  />
                                                            </div>
        
        
                                                        
                                                        </div>
        
                                                        <div className="col-xl-6 col-sm-12">
                                                        
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Request Date</span>
                                                                <input className="form-control" value={currRequest?.DateRequest} disabled  />
                                                            </div>
        
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Date Needed</span>
                                                                <input className="form-control" value={currRequest?.DateNeeded} disabled  />
                                                            
                                                            </div>
                                                        </div>
                                                        
        
                                                    
                                                    </div>
                                                </div>
        
        
                                            </div>

                                            {menu == 'Requests' &&
                                                <div className="row w-100">
                                                    <div className="col-12 w-100">
                                                        <div className="blue-card row g-2 bottomApproval" style={{justifyContent:'center'}}>
                                                            
                                                            <div 
                                                                style={{borderRadius:100}}
                                                                className="btn custom btn-danger"
                                                                onClick={() =>  {
                            
                                                                    let reason = prompt(`Please enter a reason for denying ${currRequest.OriginatorName.split(' ')[0]}'s request :`, "");
                                                                    if (reason == null || reason == "") {
                                                                        console.log('denial comment is null')
                                                                    } else {
                                                                        console.log('denial comment is ==> ',reason)
                                                                        solveRequest('denied',reason)
                                                                    }
                                                                    
                                                                }}
                                                            >
                                                                Deny
                                                            </div>
                                                            
                                                            <div 
                                                                style={{borderRadius:100}} 
                                                                className="btn custom btn-primary"
                                                                //level,id,name,status,steps,email
                                                                onClick={() => {
                                                                    // let multilevel = prompt(`Please enter a reason for denying ${currRequest.OriginatorName.split(' ')[0]}'s request :`, "");
                                                                    // if (reason == null || reason == "") {
                                                                    //     console.log('denial comment is null')
                                                                    // } else {
                                                                    //     console.log('denial comment is ==> ',reason)
                                                                    //     solveRequest('denied',reason)
                                                                    // }
                                                                    solveRequest('approved',null)
                                                                }}
                                                            >
                                                                Approve
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        
                                    )

                                    :

                                    (
                                        <div className="text-center">
                                            <Player
                                                autoplay
                                                loop
                                                src={resolution == 'approved'? 'https://lottie.host/78f35b4a-14fd-4afe-8abe-2fb8073e8b9c/UEK55G0hRx.json' : 'https://lottie.host/a1fce545-f060-4366-990a-bc104d793d99/LokY6kJs0B.json'}
                                                style={{ width: '25%',justifyContent:'center', alignItems:'center',marginTop:'1%'}}
                                            />

                                            <p>{currRequest?.OriginatorName.split(' ')[0]} will be notified his request has been {resolution}, thanks!</p>
                                        </div>
                                       
                                    )
                                }   
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
          
            <div className='col-xl-2 col-lg-2 col-md-2 d-flex h-100 mobilecontrol' style={{justifyContent:'center',alignItems:'center'}}>
                <NavbarApprover 
                    onClick1={() => isMenu('Requests')}
                    onClick2={() => isMenu('History')}
                    onClick3={() => isMenu('Insights')}
                    Selected = {menu}
                />
            </div>
            
            {/* <div className="col-1 hide" /> */}

            <div className="col-xl-10 col-lg-10 col-md-10 col-12 h-100">
                <div 
                    className='custom-card approver w-100 animate__animated animate__fadeIn'
                    style={{marginLeft:0}}
                >
                    <div className="w-100" style={{height:'95%',overflowY:'auto',overflowX:'hidden'}}>
                      
                        <h1 className="mb-0 approverTitle">Hi, {profile?.name.split(' ')[0]}</h1>
                        <small style={{marginLeft:'1.2%',color:'darkgray'}}>Level {level} {departments? `- Department(s): ${departments}` : ''}</small>
                        <br/>
                        <small style={{marginLeft:'1.2%'}}>
                            {menu == 'Requests' &&
                                <>
                                    {data.length > 0?
                                        (<>Here are your unresolved requests.</>)
                                        :
                                        (<>There are no unresolved requests.</>)    
                                    }
                                </>
                                
                            }
                            {menu == 'History' &&
                                
                                <>
                                {allrequests.length > 0?
                                    (<>Here is the history of all your requests.</>)
                                    :
                                    (<>There is no recorded history yet.</>)    
                                }
                            </>
                            }
                            {menu == 'Insights' &&
                                <>Here are your Capex insights this year.</>
                            }
                        </small>
                        <br/> <br/>
                        <div className="row w-100" style={{margin:'auto'}}>
                           
                            {menu == 'Requests' &&
                                <>
                                    {loading?
                                        (
                                            <div class="d-flex justify-content-center" style={{marginTop:'15%'}}>
                                                <div class="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <>{renderCards}</>
                                        )
                                    }
                                    
                                </>
                            }
                           
                            {menu == 'History' &&
                                <div className="col-12">
                                    <div >
                                        <div className="row" style={{borderRadius:12,padding:10, overflow:'hidden',margin:'auto'}}>
                                           
                                            <div className='w-100 mb-3 text-center' style={{position:'relative',margin:'auto'}}>
                                                <span class="material-symbols-outlined search-icon">search</span>
                                                <input 
                                                
                                                    placeholder={`Search for ${submenu.toLocaleLowerCase()} requests...`} 
                                                    className='searchCustom text-center'
                                                    onChange={(e) => {
                                                        let text = e.target.value
                                                        console.log(text)
                                                        
                        
                                                        // console.log(data.filter(mydata => mydata.FirstName.includes(text)))
                                                        
                                                    
                                                        if(text.length > 0){
                                                            isSearching(true)
                                                            isSearchRes(allrequests.filter(mydata => mydata.ItemDescription?.toLowerCase().includes(text) || mydata.PartNumber?.toLowerCase().includes(text) || mydata.OriginatorName?.toLowerCase().includes(text) || mydata.ID?.toLowerCase().includes(text)))
                                                        }
                                                        else{
                                                            isSearching(false)
                                                        }
                                                        
                                                    
                                                    }}
                                                />
                                                
                                            </div>

                                            <div className="d-flex flex-row w-100 mb-3" style={{justifyContent:'center',alignItems:'center'}}>
                                                <Submenu 
                                                    Title='All' 
                                                    onClick={() => {
                                                        isSubmenu('All')
                                                        isSearching(false)
                                                    }} 
                                                />
                                                <Submenu 
                                                    Title='Approved' 
                                                    onClick={() => {
                                                        isSearching(true)
                                                        isSubmenu('Approved')
                                                        isSearchRes(allrequests.filter(mydata => mydata.Status?.toLowerCase().includes('approved') ))
                                                    }} 
                                                />
                                                <Submenu 
                                                    Title='Denied' 
                                                    onClick={() => {
                                                        isSearching(true)
                                                        isSubmenu('Denied')
                                                        isSearchRes(allrequests.filter(mydata => mydata.Status?.toLowerCase().includes('denied') ))
                                                    }} 
                                                />
                                            </div>

                                            <div className="row w-100" style={{margin:'auto'}}>
                                                {loaded?
                                                    (
                                                        <>
                                                            {!error.error?
                                                                (
                                                                    <>
                                                                        {!search?
                                                                            (
                                                                                <>{renderAll}</>
                                                                            )
                                                                            :
                                                                            (
                                                                                <>{searchCards}</>
                                                                            )
                                                                        }
                                                                    </>
                                                                )

                                                                :

                                                                (
                                                                    <div className='errorBox'>
                                                                        <p className='mb-0'>An error occured: <b>{error.message}.</b></p>
                                                                        <hr/>
                                                                        <p className='mb-0'>Refresh the page to fix it or submit a ticket via HelpDesk if the issue persists</p>
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        
                                                        </>
                                                    )

                                                    :

                                                    (
                                                        <div className={`custom-card animate__animated animate__fadeIn`} style={{overflow:'hidden', marginTop:'8%'}}>
                                                            <div class="d-flex justify-content-center w-100 h-100">
                                                                <div class="spinner-border text-primary loader" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                
                                                }
                                               
                                            </div>
                                        </div>
                                    </div>

                                 
                                </div>
                            }

                            {menu == 'Insights' &&
                                <div className="col-12 h-100">

                                    <div className="row" >
                                        <div className="col-xl-5 col-12 d-flex" style={{margin:'auto',maxHeight:300, justifyContent:'center',position:'relative'}}>
                                            
                                           
                                            <Doughnut 
                    
                                                data={{
                                                    labels: budgetPerDepartment?.label,
                                                    datasets: [{
                                                        label: 'Amount Approved',
                                                        data: budgetPerDepartment?.data,
                                                        backgroundColor: ['#42A5F5', '#EF5350','#00ACC1', '#AB47BC','#FFEE58','#8D6E63','#00BCD4','#8BC34A','#E91E63'],
                                                    }]
                                                }}
                                                width={'100%'}
                                                options={{ 
                                                    maintainAspectRatio: true ,
                                                    plugins: {
                                                        legend: {
                                                            display: showlegend,
                                                            position: 'bottom'
                                                        }
                                                    }
                                                }}
                                            
                                            />
                                            <span className="material-symbols-outlined legend" onClick={() => setLegend(!showlegend)}>info</span>
                                        </div>
                    
                                        <div className="col-xl-7 col-12" style={{margin:'auto'}}>
                                            <Chart
                                                type='bar'
                                                data={{
                                                    labels: ['oct','nov','dec','jan', 'feb', 'mar', 'apr', 'may', 'jun','jul','aug','sep'],
                                                    datasets: [{
                                                        label: 'Amounts You Approved Per Month',
                                                        data: spentMonthly,
                                                
                                                    }]
                                                }}
                                                height={'100%'}
                                                options={{ maintainAspectRatio: true }}
                                            />
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="row h-100">
                                        
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100" >
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Approved Budget</small>
                                                <h1 className="reallyBigNumber">${formatMoney(stats.approved_money)}</h1>
                                            </div>
                                        </div>
                                     
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100" >
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Total Requests</small>
                                                <h1 className="reallyBigNumber">{stats.total}</h1>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100" >
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Approved Requests</small>
                                                <h1 className="reallyBigNumber">{stats.approved}</h1>
                                            </div>
                                        </div>

                                        

                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3 settings-col text-center h-100">
                                            <div className="d-flex h-100 statsCard animate__animated animate__flipInY">
                                                <small style={{position:'absolute',top:'10%'}}>Denied Requests</small>
                                                <h1 className="reallyBigNumber">{stats.denied}</h1>
                                            </div>
                                        </div>

                                        <div className="d-flextext-center" style={{margin:'auto',marginTop:'2%'}}>
                                            <div class="d-flex settingsCard">
                                                <p class="mb-0 w-100 text-center"><b>Version Number -</b> {app.version}</p>
                                            </div>
                                        </div>

                                       
                                    </div>
                                </div>
                            }
                            
                        </div>
                      
                    </div>
                </div>

                <BottomNavbarApprover 
                    onClick1={() => {
                        isSelected('Requests')
                        isMenu('Requests')
                    }}
                    onClick2={() => {
                        isSelected('History')
                        isMenu('History')
                    }}
                    onClick3={() => {
                        isSelected('Insights')
                        isMenu('Insights')
                    }}
                    Selected = {selected}
                />
            </div>

            <div className="col-1 hide" />
            {/* <div className="col-2" /> */}
           
        </div>
    )
}

export default Approver