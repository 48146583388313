const NotifCard = (props) => (
    <div 
        className={`d-flex notifCard animate__animated animate__fadeIn ${props.Complete}`} 
        onClick={props.onClick} 
        data-bs-toggle="modal" 
        data-bs-target="#detailsCard"
    >
        <span className="material-symbols-outlined" style={{marginRight:5}}>{props.Icon}</span>
        <p className="mb-0 ellipsis">Request [{props.ID}] - {props.Name}</p>
    </div>
)

export default NotifCard